window.addEventListener('DOMContentLoaded', () => {
    function mapServerToAlias(url) {
        const serverNumberMatch = url.match(/n(\d+)/);
        if (!serverNumberMatch || !serverNumberMatch[1]) {
            return null;
        }
        const serverNumber = serverNumberMatch[1];
        const aliases = {
            '15': 'Pizza',
            '16': 'Burger',
            '17': 'Nugget',
            '18': 'Fries',
            '19': 'Meatballs',
            '20': 'Milkshake',
            '21': 'Kebab™',
            '22': 'Taquito',
            '23': 'Nachos',
            '24': 'Ramen',
            '25': 'Wiener',
            '26': 'Soup',
        };
        return aliases[serverNumber] || `Unknown Server (n${serverNumber})`;
    }

    function displayServerName(serverName) {
        const maxSizeElement = document.querySelector('#maxSize .text-xs');
        if (maxSizeElement) {
            const message = document.createElement('p');
            message.textContent = `Uploading to server: ${serverName}`;
            message.style.fontSize = '14px';
            message.style.color = 'rgb(var(--color-main)/var(--tw-text-opacity))';

            // Insert the server name AFTER the target element
            maxSizeElement.parentNode.insertBefore(message, maxSizeElement.nextSibling);
        }
    }

    document.addEventListener('nodeApiSet', function () {
        setTimeout(function () {
            if (page.nodeApi) {
                const alias = mapServerToAlias(page.nodeApi);
                if (alias) {
                    const serverName = `${alias} (${page.nodeApi.match(/n\d+/)[0]})`;
                    displayServerName(serverName);
                }
            }
        }, 100); 
    });
});
